import React from "react"

import { PopoverClose } from "@radix-ui/react-popover"

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../UI/carousel"
import { Button } from "../UI/button"

export function Slider({ data, setSelected }) {
  const [api, setApi] = React.useState()
  const [current, setCurrent] = React.useState(0)
  const [count, setCount] = React.useState(0)

  React.useEffect(() => {
    if (!api) {
      return
    }

    setCount(api.scrollSnapList().length)
    setCurrent(api.selectedScrollSnap() + 2)

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1)
      setSelected(api.selectedScrollSnap())
    })
  }, [api])

  return (
    <Carousel
      setApi={setApi}
      className="w-full max-w-sm"
      opts={{
        startIndex: 1,
        loop: true,
      }}
    >
      <CarouselContent>
        {data.map(record => (
          <CarouselItem key={`panel-${record.id}`}>
            <div className="p-1">
              <div className="flex items-center justify-center overflow-hidden rounded-md">
                <img src={record.image} alt={record.name} />
              </div>
            </div>
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
      <PopoverClose asChild>
        <Button variant="outline" className="w-full">
          Выбрать
        </Button>
      </PopoverClose>
    </Carousel>
  )
}
