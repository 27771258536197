import React from "react"
import { StaticImage } from "gatsby-plugin-image"

function Header() {
  return (
    <div className="flex justify-between py-1">
      <StaticImage
        src="../../images/commercial/logo-n.png"
        alt="Логотип"
        layout="fixed"
        quality={100}
      />

      <div className="flex flex-col justify-center text-right">
        <a
          href="tel:+79109105535"
          className="text-gray-700 hover:text-gray-900 hover:underline"
        >
          8 (910) 910-55-35
        </a>
        <a
          href="mailto:woodcreative@ya.ru"
          className="text-gray-700 hover:text-gray-900 hover:underline"
        >
          woodcreative@ya.ru
        </a>
      </div>
    </div>
  )
}

export default Header
