import React, { useState } from "react"

function EditForm({ todo, setEdited, handleChange }) {
  const [price, setPrice] = useState(todo?.price)
  const [quantity, setQuantity] = useState(todo?.quantity)
  const [userInput, setUserInput] = useState(todo?.userInput)

  return (
    <div
      id={todo.id}
      key={todo.id + todo.task}
      name="todo"
      value={todo.id}
      // onClick={handleClick}
      className="grid grid-cols-6 gap-1 border-y border-gray-300 pt-2 text-sm"
    >
      <img
        src={todo?.selectedId}
        alt={todo?.selectedName}
        className="-mt-2 h-28"
      />

      <div className="col-span-2 ">
        <label className="text-sm">
          <textarea
            value={userInput}
            onChange={e => setUserInput(e.target.value)}
            rows="4"
            className="w-80 rounded-lg border border-gray-300 p-1 text-xs"
            required
          />
        </label>
      </div>

      <div className="space-x-1">
        <label className="text-sm">
          <input
            value={quantity}
            onChange={e => setQuantity(e.target.value)}
            type="number"
            name="quantity"
            className="w-16 rounded-lg border border-gray-300 p-1 text-xs"
            required
          />
        </label>
        <span className="ml-2 text-gray-700">{todo?.selectedValue}</span>
      </div>

      <label className="text-sm">
        <input
          value={price}
          onChange={e => setPrice(e.target.value)}
          type="number"
          name="price"
          className="mr-2 w-24 rounded-lg border border-gray-300 p-1 text-xs"
          required
        />
        <span className="ml-2 text-gray-700">руб.</span>
      </label>

      <div className="flex flex-col justify-between">
        <p>{price * quantity} руб.</p>

        <div className="flex justify-end space-x-2 p-1 print:hidden">
          <button
            onClick={() => {
              handleChange(todo.id, {
                userInput,
                quantity,
                price,
                sum: quantity * price,
              })
              setEdited(false)
            }}
            type="button"
            className="rounded-md border border-gray-300 bg-green-100 px-1 text-xs hover:bg-green-200"
          >
            Сохранить
          </button>
          <button
            onClick={() => setEdited(false)}
            type="button"
            className="rounded-md border border-red-300 bg-red-100 px-1 text-xs hover:bg-red-200"
          >
            Отмена
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditForm
