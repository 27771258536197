import React, { useState } from "react"
import { PencilIcon, XCircleIcon } from "@heroicons/react/24/outline"
import EditForm from "./EditForm"

const ToDo = ({ todo, handleChange, handleDelete }) => {
  // const handleClick = e => {
  //   e.preventDefault()
  //   handleToggle(e.currentTarget.id)
  // }
  const [isEdited, setEdited] = useState(false)

  if (isEdited)
    return (
      <EditForm todo={todo} setEdited={setEdited} handleChange={handleChange} />
    )

  return (
    <div
      id={todo.id}
      key={todo.id + todo.task}
      name="todo"
      value={todo.id}
      // onClick={handleClick}
      className="grid break-inside-avoid grid-cols-6 gap-1 border-y border-gray-300 pt-2 text-sm"
    >
      <img
        src={todo?.selectedId}
        alt={todo?.selectedName}
        className="-mt-2 h-28"
      />
      <p className="col-span-2 break-normal">{todo?.userInput}</p>

      <p className="justify-self-end">
        {todo?.quantity} {todo?.selectedValue}
      </p>
      <p className="justify-self-center">{todo?.price} руб.</p>
      <div className="flex flex-col justify-between">
        <p>{todo?.sum} руб.</p>

        <div className="flex space-x-2 p-1 print:hidden">
          <button
            type="button"
            onClick={() => setEdited(true)}
            className="inline-flex items-center pt-[0.5px] text-xs text-gray-700 hover:text-red-900"
          >
            <PencilIcon className="mr-1 h-4 w-4" aria-hidden="true" />
            Редактировать
          </button>
          <button
            onClick={() => handleDelete(todo.id)}
            type="button"
            className="inline-flex items-center text-xs text-red-700 hover:text-red-900"
          >
            <XCircleIcon className="mr-1 h-5 w-5" aria-hidden="true" />
            Удалить
          </button>
        </div>
      </div>
    </div>
  )
}

export default ToDo
