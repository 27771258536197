import React from "react"

import img1 from "../../images/commercial/tracking.png"
import img2 from "../../images/commercial/delivery-time.png"
import img3 from "../../images/commercial/delivery-time.png"
import img4 from "../../images/commercial/cash.png"
import img5 from "../../images/commercial/box.png"
import img6 from "../../images/commercial/delivery.png"

import img7 from "../../images/commercial/delivery-min.png"
import img8 from "../../images/commercial/container.png"
import img9 from "../../images/commercial/cargo.png"
import img10 from "../../images/commercial/delivery-bike.png"
import img11 from "../../images/commercial/target.png"
import img12 from "../../images/commercial/clipboard.png"

function Info() {
  return (
    <div className="space-y-4">
      <div style={{ breakAfter: "page" }} />
      <h2 className="border-b border-gray-300">ЧТО ВАЖНО ЗНАТЬ ПРИ ЗАКАЗЕ?</h2>
      <div className="grid grid-cols-2 gap-2 pb-4">
        <div>
          <img
            src={img1}
            alt="СТОИМОСТЬ ИЗГОТОВЛЕНИЯ"
            className="my-3 h-20 w-auto"
          />
          <h3 className="text-orange-800">
            ЧТО ВХОДИТ В СТОИМОСТЬ ИЗГОТОВЛЕНИЯ?
          </h3>
          <p>— изделия без учета доставки и монтажа</p>
          <ul className="list-inside list-disc pl-4 pt-1 text-xs">
            <li>изготовление изделий</li>
            <li>изготовление крепежей при указании в расчете</li>
            <li>отделка и тонировка в указанный цвет</li>
          </ul>
        </div>

        <div>
          <img src={img2} alt="СРОКИ и НАЛИЧИЕ" className="my-3 h-20 w-auto" />
          <h3 className="text-orange-800">СРОКИ и НАЛИЧИЕ</h3>
          <p>— 2-6 недель – на большинство позиций</p>
          <ul className="list-inside list-disc pl-4 pt-1 text-xs">
            <li>сроки зависят от очереди заказов</li>
            <li>изделия изготавливаются индивидуально под заказ</li>
          </ul>
        </div>

        <div>
          <img
            src={img3}
            alt="КОГДА и КАК ЗАКАЗЫВАТЬ"
            className="my-3 h-20 w-auto"
          />
          <h3 className="text-orange-800">КОГДА и КАК ЗАКАЗЫВАТЬ?</h3>
          <p>— за 1-2 мес. до окончания ремонта</p>
          <ul className="list-inside list-disc pl-4 pt-1 text-xs">
            <li>оптимальное время заказа – на этапе чистовых работ</li>
            <li>для заказа позвоните или напишите нам</li>
            <li>заказ возможен дистанционно или в шоу-руме</li>
            <li>пришлите данные и получите договор</li>
            <li>
              оплата производится только за изготовление (без доставки и
              монтажа)
            </li>
          </ul>
        </div>

        <div>
          <img src={img4} alt="ОПЛАТА" className="my-3 h-20 w-auto" />
          <h3 className="text-orange-800">ОПЛАТА</h3>
          <p>— Физ.лицо или от компании</p>
          <ul className="list-inside list-disc pl-4 pt-1 text-xs">
            <li>min заказ - 40 000 р.</li>
            <li>для физ.лиц – картой/наличными</li>
            <li>для юр.лиц – безнал по счету</li>
          </ul>
        </div>

        <div>
          <img src={img5} alt="УПАКОВКА" className="my-3 h-20 w-auto" />
          <h3 className="text-orange-800">УПАКОВКА</h3>
          <p>— выполняется по умолчанию в защиту стандартных размеров</p>
          <ul className="list-inside list-disc pl-4 pt-1 text-xs">
            <li>стандартная: подложка, картон, скотч</li>
            <li>1-2 упаковки можно разместить в легковом авто</li>
            <li>иногда упаковки не вмещаются в грузовой лифт</li>
            <li>перевозка в пассажирском лифте – невозможна</li>
            <li>
              мы НЕ рекомендуем вскрывать упаковку в процессе доставки и подъема
            </li>
          </ul>
        </div>

        <div>
          <img src={img6} alt="ДОСТАВКА" className="my-3 h-20 w-auto" />
          <h3 className="text-orange-800">ДОСТАВКА</h3>
          <p>— не включена в стоимость изделий и оплачивается отдельно</p>
          <ul className="list-inside list-disc pl-4 pt-1 text-xs">
            <li>занимает с учетом согласований 1-3 дня</li>
            <li>выполняется после 100% оплаты заказа</li>
            <li>осуществляется проверенными партнерскими ТК</li>
            <li>стоимость зависит от адреса и объема заказа</li>
            <li>точная стоимость определяется ТК</li>
            <li>
              изделия хрупкие и сверхгабаритные, поэтому мы рекомендуем
              пользоваться только проверенными ТК
            </li>
            <li>разгрузка и подъем – возможны (см.след.раздел)</li>
          </ul>
        </div>

        <div />
        <div style={{ breakAfter: "page" }} className="py-4" />

        <div>
          <img src={img7} alt="РАЗГРУЗКА/ПОДЪЕМ" className="my-3 h-20 w-auto" />
          <h3 className="text-orange-800">РАЗГРУЗКА/ПОДЪЕМ</h3>
          <p>— не включена в стоимость и рассчитываются отдельно</p>
          <ul className="list-inside list-disc pl-4 pt-1 text-xs">
            <li>самостоятельно или грузчиками</li>
            <li>требует 2-х человек</li>
            <li>вес 1 упаковки – 30-45 кг</li>
            <li>длина упаковки – 2,88-3,35 м</li>
            <li>на груз.лифте: по Мск – от 2000 р., СПб – от 1500 р.</li>
            <li>
              пешком по лестнице: по Мск - 3000 р + 200 р/эт. за упак., по
              регионам – 1500 р. + 200 р/эт.
            </li>
            <li>
              стоимость зависит от количества упаковок, способа подъема и этажа
            </li>
          </ul>
        </div>

        <div>
          <img
            src={img8}
            alt="МОНТАЖ/ УСТАНОВКА"
            className="my-3 h-20 w-auto"
          />
          <h3 className="text-orange-800">МОНТАЖ/ УСТАНОВКА</h3>
          <p>
            — не включена в стоимость изготовления (в среднем 20-40% от
            стоимости изделий)
          </p>
          <ul className="list-inside list-disc pl-4 pt-1 text-xs">
            <li>самостоятельно или профессиональными партнерами</li>
            <li>монтаж требует специальных инструментов и опыт</li>
            <li>
              профессиоанльный монтаж выполняют партнеры, контакты которых
              предоставляются по запросу
            </li>
            <li>стоимость зависит от объема заказа, адреса и нюансов</li>
            <li>
              установка изделий выполняется после малярных и влажных работ и до
              мебели
            </li>
          </ul>
        </div>

        <div>
          <img src={img9} alt="ЗАМЕР" className="my-3 h-20 w-auto" />
          <h3 className="text-orange-800">ЗАМЕР</h3>
          <p>
            — в 90% случаев не требуется, т.к. изделия изготавливаются
            стандартных размеров и подрезаются по месту
          </p>
          <ul className="list-inside list-disc pl-4 pt-1 text-xs">
            <li>возможен как доп.услуга в Мск, Калуге и области</li>
            <li>выполняется ТОЛЬКО после получения предварительного расчета</li>
            <li>рекомендуется на этапе чистовых работ</li>
            <li>выполняется менеджером</li>
          </ul>
        </div>

        <div>
          <img
            src={img10}
            alt="КАК ОЗНАКОМИТЬСЯ С ИЗДЕЛИЯМИ"
            className="my-3 h-20 w-auto"
          />
          <h3 className="text-orange-800">КАК ОЗНАКОМИТЬСЯ С ИЗДЕЛИЯМИ?</h3>
          <p>— заказать образцы, посетить шоу-румы</p>
          <ul className="list-inside list-disc pl-4 pt-1 text-xs">
            <li>в Мск – шоу-рум</li>
            <li>в Калужской области – посетить производство в Малоярославце</li>
            <li>заказать образцы на адрес</li>
            <li>вы можете сделать заказ, а цвет выбрать позже</li>
          </ul>
        </div>

        <div>
          <img src={img11} alt="ПРИЕМКА" className="my-3 h-20 w-auto" />
          <h3 className="text-orange-800">ПРИЕМКА</h3>
          <p>— важный этап при получении заказа</p>
          <ul className="list-inside list-disc pl-4 pt-1 text-xs">
            <li>
              обязательно проверить упаковку, при наличии повреждений –
              зафиксировать на фото
            </li>
            <li>при получении заказа подпишите акт или составьте претензию</li>
            <li>
              3 этапа проверки – проверка изделий, проверка упаковки при
              водителе, проверка после подъема
            </li>
          </ul>
        </div>

        <div>
          <img src={img12} alt="ГАРАНТИИ" className="my-3 h-20 w-auto" />
          <h3 className="text-orange-800">ГАРАНТИИ</h3>
          <p>— 1 год</p>
          <ul className="list-inside list-disc pl-4 pt-1 text-xs">
            <li>
              За сохранность изделий и упаковки при доставке отвечают
              партнерские транспортные компании
            </li>
            <li>
              За выгрузку/подъем отвечают исполнители погрузо разгрузочных работ
            </li>
            <li>
              За качество монтажных работ отвечают монтажники. Гарантия на
              работы согласовывается индивидуально
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Info
