import React, { useState, useRef, useEffect } from "react"
import { useReactToPrint } from "react-to-print"

import { XCircleIcon } from "@heroicons/react/20/solid"

import Wrapper from "../Forms/Wrapper"

import ToDoList from "./ToDoList"
import ToDoForm from "./ToDoForm"
import SaveButton from "../UI/SaveButton"
import CleanButton from "../UI/CleanButton"

import { getENDate } from "../helpers"

import Header from "./Header"
import { useLocalStorage } from "../hooks"
import Info from "./Info"

import { useRecoilState } from "recoil"
import { userState } from "../../store"

function Commercial() {
  const componentRef = useRef()

  const [user, setUser] = useRecoilState(userState)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const [toDoList, setToDoList] = useLocalStorage("todos", [])

  // const handleToggle = id => {
  //   let mapped = toDoList.map(task => {
  //     return task.id === Number(id)
  //       ? { ...task, complete: !task.complete }
  //       : { ...task }
  //   })
  //   setToDoList(mapped)
  // }

  const handleChange = (id, changes) => {
    let mapped = toDoList.map(task => {
      return task.id === Number(id) ? { ...task, ...changes } : { ...task }
    })
    setToDoList(mapped)
  }

  const handleDelete = id => {
    let filtered = toDoList.filter(task => {
      return task.id !== Number(id)
    })
    setToDoList(filtered)
  }

  // const handleFilter = () => {
  //   let filtered = toDoList.filter(task => {
  //     return !task.complete
  //   })
  //   setToDoList(filtered)
  // }

  const addTask = ({
    selectedId,
    selectedName,
    userInput,
    price,
    quantity,
    selectedValue,
    sum,
  }) => {
    let copy = [...toDoList]
    copy = [
      ...copy,
      {
        id: toDoList.length + 1,
        complete: false,
        selectedId,
        selectedName,
        userInput,
        price,
        quantity,
        selectedValue,
        sum,
      },
    ]
    setToDoList(copy)
  }

  const [date, setDate] = useState(getENDate())
  const [name, setName] = useLocalStorage("manager_name", "")
  const [phone, setPhone] = useLocalStorage("manager_phone", "")

  const [isAlertVisible, setAlertVisible] = useState(false)

  useEffect(() => {
    if (phone === "" || phone === null || phone === undefined) {
      setPhone(user?.phone)
    }
  }, [user?.phone])

  useEffect(() => {
    if (name !== "") setAlertVisible(false)
  }, [name])

  return (
    <div className="space-y-4">
      <Wrapper>
        <div ref={componentRef} className="print:p-6">
          <h2 className="text-center text-xs">
            Коммерческое предложение | Wood Creative
          </h2>
          <Header />
          <div className="flex justify-between border-b border-gray-300 pb-2">
            <div className="space-x-2">
              <label className="text-sm">
                Менеджер:
                <input
                  value={name}
                  onChange={e => setName(e.target.value)}
                  type="text"
                  name="name"
                  placeholder="Имя менеджера"
                  className="ml-1 rounded-lg border border-gray-300 p-1 text-sm"
                />
              </label>
              <label className="text-sm">
                Телефон:
                <input
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  type="tel"
                  name="phone"
                  placeholder="Номер телефона"
                  className="ml-1 rounded-lg border border-gray-300 p-1 text-sm"
                />
              </label>
            </div>
            <div>
              <label htmlFor="start" className="text-sm">
                Дата:
              </label>

              <input
                type="date"
                id="start"
                name="com-start"
                value={date}
                onChange={e => setDate(e.target.value)}
                className="ml-2 rounded-lg border border-gray-300 p-1 text-sm"
              />
            </div>
          </div>
          <div className="grid grid-cols-6 gap-1 divide-y-0 divide-gray-300 py-1 text-sm">
            <div>Фото</div>
            <div className="col-span-2">Наименование</div>
            <div className="justify-self-end">Количество</div>
            <div className="justify-self-center">Цена</div>
            <div>Сумма</div>
          </div>

          <ToDoList
            toDoList={toDoList}
            handleDelete={handleDelete}
            handleChange={handleChange}
          />

          <div className="hidden pt-8 print:block">
            <Info />
          </div>
        </div>
      </Wrapper>
      {isAlertVisible ? (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Невозможно сохранить документ
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <ul className="list-disc space-y-1 pl-5">
                  <li>Не указано имя менеджера</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className="space-x-1">
        <SaveButton
          onClick={() => {
            name === "" ? setAlertVisible(true) : handlePrint()
          }}
        />
        <CleanButton onClick={() => setToDoList([])} />
      </div>

      <Wrapper>
        <ToDoForm addTask={addTask} />
      </Wrapper>
    </div>
  )
}

export default Commercial
