import React from "react"
import ToDo from "./ToDo"
import EmptyList from "./EmptyList"

const ToDoList = ({ toDoList, handleDelete, handleChange }) => {
  const sum = toDoList.reduce((accumulator, object) => {
    return accumulator + object.sum
  }, 0)

  return (
    <div className="space-y-2">
      {toDoList.map(todo => {
        return (
          <ToDo
            key={`todo-${todo.id}`}
            todo={todo}
            handleChange={handleChange}
            handleDelete={handleDelete}
          />
        )
      })}

      {toDoList.length === 0 ? <EmptyList /> : null}
      <p className="border-t border-gray-300 text-right">
        Итого: <span className="font-semibold">{sum} руб.</span>
      </p>

      <div className="hidden print:block">
        <p className="text-xs">Цены в предложении - в рублях (₽).</p>
        <p className="text-xs">
          КП не является платежным документом/счетом, действует в течении 15
          дней, окончательная стоимость и возможность изготовления уточняются
          индивидуально перед оформлением договора.
        </p>
        <p className="text-xs underline decoration-yellow-400 decoration-2 underline-offset-1">
          Стоянка транспортного средства на всё время проведения монтажных
          работ, обеспечивается силами КЛИЕНТА!
        </p>
      </div>
    </div>
  )
}

export default ToDoList
