import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Commercial from "../components/Commercial"

const CommercialPage = () => (
  <Layout>
    <SEO title="Коммерческое предложение" />
    <Commercial />
  </Layout>
)

export default CommercialPage
